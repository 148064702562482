// app/javascript/controllers/country_select_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["label", "dropdown"];

  toggleDropdown() {
    this.dropdownTarget.classList.toggle('hidden');
  }

  selectCountry(event) {
    event.preventDefault();
    const countryCode = event.currentTarget.getAttribute('data-country-code');
    this.labelTarget.textContent = countryCode;
    this.toggleDropdown();
    // Aquí deberías actualizar el valor de un campo oculto que realmente se envíe con el formulario
  }
}
