import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["step", "form", "triggerNext", "triggerBack"];

  initialize() {
    this.showCurrentStep(0); // Muestra el primer paso inicialmente
  }
  
  triggerNextTargetConnected() {
    this.next();
    this.triggerNextTarget.remove();
  }
  
  triggerBackTargetConnected() {
    this.previous();
    this.triggerBackTarget.remove();
  }

  next() {
    const currentIndex = this.currentIndex();
    if (currentIndex < this.stepTargets.length - 1) {
      this.showCurrentStep(currentIndex + 1);
    }
  }
  
  previous() {
    const currentIndex = this.currentIndex();
    if (currentIndex > 0) {
      this.showCurrentStep(currentIndex - 1);
    }
  }

  showCurrentStep(index) {
    this.stepTargets.forEach((el, i) => {
      // el.classList.toggle("current-step", i === index); // Asumiendo que `current-step` es una clase para resaltar el paso //actual
      if (el.querySelector("svg")) {
        el.querySelector("svg").classList.remove(
          "text-blue-600",
          "dark:text-blue-500"
        );
        el.querySelector("svg").classList.add(
          "text-gray-600",
          "dark:text-gray-500"
        );
      }
      if (i <= index) {
        el.classList.remove("text-gray-600", "dark:text-gray-500");
        el.classList.add("text-blue-600", "dark:text-blue-400");
        el.querySelector("span").classList.remove(
          "border-gray-600",
          "dark:border-gray-500"
        );
        el.querySelector("span").classList.add(
          "border-blue-600",
          "dark:border-blue-500"
        );
        if (this.stepTargets[i - 1]) {
          this.stepTargets[i - 1]
            .querySelector("svg")
            .classList.add("text-blue-600", "dark:text-blue-500");
          this.stepTargets[i - 1]
            .querySelector("svg")
            .classList.remove("text-gray-600", "dark:text-gray-500");
        }
      } else {
        el.classList.remove("text-blue-600", "dark:text-blue-400");
        el.classList.add("text-gray-600", "dark:text-gray-500");
        el.querySelector("span").classList.remove(
          "border-blue-600",
          "dark:border-blue-500"
        );
        el.querySelector("span").classList.add(
          "border-gray-600",
          "dark:border-gray-500"
        );
      }
    });
  }

  currentIndex() {
    // Devuelve el índice del paso actual
    return (this.stepTargets.filter(
      (el) => el.classList.contains("text-blue-600") || el.classList.contains("dark:text-blue-400")
    ).length - 1);
  }

  handleResponse(event) {
    if (event.detail.fetchResponse.response.status === 200) {
      this.next();
    }
  }
}