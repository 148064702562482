// app/javascript/controllers/search_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "input", "selected_id"]
  static values = { url: String, id: String }

  search(event) {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
        // Evita que el formulario se envíe de la manera tradicional
        event.preventDefault();
        
        const query = event.target.value;
        const data = { query: query, id: this.idValue };
        // Realiza la solicitud fetch
        fetch(this.urlValue, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector("[name='csrf-token']").content, // necesitas agregar el token CSRF para las solicitudes POST en Rails
            Accept: "text/vnd.turbo-stream.html" // Importante para que Turbo pueda procesar la respuesta
          },
          body: JSON.stringify(data)
        })
        .then(r => r.text())
        .then(html => {
          Turbo.renderStreamMessage(html)
        });
      }, 100)
  }

  selected(event){
    const id = event.params.id;
    const container = event.target.closest('div');
    // the event.target is a div element with a span inside with the name of the element i want extract the name
    const name = container.querySelector('span').innerText
    this.inputTarget.value = name
    this.selected_idTarget.value = id
    const data = { query: '', id: this.idValue};
    fetch(this.urlValue, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content, // necesitas agregar el token CSRF para las solicitudes POST en Rails
        Accept: "text/vnd.turbo-stream.html" // Importante para que Turbo pueda procesar la respuesta
      },
      body: JSON.stringify(data)
    })
    .then(r => r.text())
    .then(html => Turbo.renderStreamMessage(html));
  }
}