import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["field", "latitude", "longitude", "city", "state"];
  static values = { coordinates: Array, city: String, state: String };

  connect() {
    this.initializeAutocomplete();
  }

  initializeAutocomplete() {
    console.log(this.coordinatesValue);
    if (typeof google === 'undefined' || !google.maps || !google.maps.places) {
      setTimeout(() => this.initializeAutocomplete(), 100);
      return;
    }

    if (!this.fieldTarget) return;

    const [userLatitude, userLongitude] = this.coordinatesValue;

    const defaultBounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(userLatitude, userLongitude)
    );

    const options = {
      bounds: defaultBounds,
      strictBounds: false,
      types: ['(cities)'],
      fields: ["address_components", "geometry", "icon", "name"],
      language: "es",
      componentRestrictions: { country: "mx" },
    };

    this.autocomplete = new google.maps.places.Autocomplete(
      this.fieldTarget,
      options
    );
    this.autocomplete.addListener(
      "place_changed",
      this.placeChanged.bind(this)
    );
    this.setDefaultCity();
  }

  setDefaultCity() {

    if (this.cityValue) {
      this.fieldTarget.value = `${this.cityValue}, ${this.stateValue}, México`;
      this.cityTarget.value = this.cityValue;
      this.stateTarget.value = this.stateValue;
      this.latitudeTarget.value = this.coordinatesValue[0];
      this.longitudeTarget.value = this.coordinatesValue[1];
    }
  }

  placeChanged() {
    let place = this.autocomplete.getPlace();
    if (!place.geometry) {
      window.alert(`No details available for input: ${place.name}`);
      return;
    }
    this.latitudeTarget.value = place.geometry.location.lat();
    this.longitudeTarget.value = place.geometry.location.lng();
    place.address_components.forEach((obj) => {
      switch (obj.types[0]) {
        case "locality":
          this.cityTarget.value = obj.long_name;
          break;
        case "administrative_area_level_1":
          this.stateTarget.value = obj.long_name;
          break;
      }
    });
  }
}