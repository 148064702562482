import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="institution"
export default class extends Controller {
  static targets = [
    "institutionForm",
    "institutionNameField",
    "institutionDropdown",
    "institutionIdField",
  ];

  connect() {
    this.eventListenersForInstitutionField();
  }

  eventListenersForInstitutionField() {
    // Por cada formulario de institución, buscamos el campo de institución
    // y le agregamos los eventos de mouseover, mouseout, input, focus y focusout
    // para mostrar y ocultar los resultados de la búsqueda
    this.institutionFormTargets.forEach((element) => {
      // Buscamos los elementos del formulario por medio de los targets
      this.institutionNameField = element.querySelector(
        "[data-institution-target='institutionNameField']"
      );
      this.institutionDropdown = element.querySelector(
        "[data-institution-target='institutionDropdown']"
      );
      this.institutionIdField = element.querySelector(
        "[data-institution-target='institutionIdField']"
      );

      // Creamos una "bandera" para saber si el mouse está sobre el div
      // la cual ira cambiando su valor dependiendo del evento
      let isMouseOverDiv = false;

      // Este evento se dispara cuando el mouse entra al div que contiene los resultados de la búsqueda
      // y muestra los resultados
      this.institutionDropdown.addEventListener("mouseover", () => {
        isMouseOverDiv = true;
        this.institutionDropdown.classList.remove("hidden");
      });

      // Este evento se dispara cuando el mouse sale del div que contiene los resultados de la búsqueda
      // y oculta los resultados
      this.institutionDropdown.addEventListener("mouseout", () => {
        isMouseOverDiv = false;
        this.institutionDropdown.classList.add("hidden");
      });

      // Este evento se dispara cuando se escribe en el campo de institución
      // Lo que hace es llamar a la función addQueryElements con el valor del campo
      // la cual se encarga de hacer la búsqueda y mostrar los resultados
      this.institutionNameField.addEventListener("input", (institution) => {
        this.addQueryElements(institution.target.value, element);
      });

      // Este evento se dispara cuando el campo de institución tiene el foco
      // y muestra los resultados de la búsqueda
      this.institutionNameField.addEventListener("focus", () => {
        this.institutionDropdown.classList.remove("hidden");
      });

      // Este evento se dispara cuando el campo de institución pierde el foco
      // y oculta los resultados de la búsqueda, solo si el mouse no está sobre el div
      this.institutionNameField.addEventListener("focusout", () => {
        if (!isMouseOverDiv) {
          this.institutionDropdown.classList.add("hidden");
        }
      });
    });
  }

  // Función que hace la búsqueda de instituciones
  searchInstitution(query) {
    if (query !== "") {
      return fetch(`/institutions.json?search=${query}`)
        .then((response) => response.json())
        .then((data) => {
          return data;
        });
    }
  }

  // Función que agrega los elementos de la búsqueda al div que los contiene
  async addQueryElements(query, container) {
    // Buscamos los elementos del formulario por medio de los targets
    this.institutionNameField = container.querySelector(
      "[data-institution-target='institutionNameField']"
    );
    this.institutionDropdown = container.querySelector(
      "[data-institution-target='institutionDropdown']"
    );
    this.institutionIdField = container.querySelector(
      "[data-institution-target='institutionIdField']"
    );

    // Hacemos la búsqueda de las instituciones
    let response = await this.searchInstitution(query);

    // Limpiamos el contenedor de resultados
    this.institutionDropdown.innerHTML = "";
    // Por cada institución encontrada, creamos un div con el nombre de la institución
    response.institutions.forEach((institution) => {
      let institutionElement = document.createElement("div");
      institutionElement.classList.add(
        "block",
        "px-4",
        "py-2",
        "text-gray-700",
        "hover:bg-gray-100",
        "active:bg-blue-100",
        "cursor-pointer",
        "rounded-md"
      );
      // Si la institución es la primera, le agregamos un padding top
      // Este se agrega para darle formato a la lista de resultados
      if (institution === response.institutions[0]) {
        institutionElement.classList.add("pt-5");
      }
      // Agregamos el nombre de la institución al div
      institutionElement.innerHTML = institution.name;
      // Agregamos un evento click al div para completar los datos del formulario
      institutionElement.addEventListener("click", () => {
        this.completeData(institution, container);
      });
      // Agregamos el div al contenedor de resultados
      this.institutionDropdown.appendChild(institutionElement);
    });
  }

  // Función que completa los datos del formulario con los datos de la institución seleccionada
  completeData(institution, container) {
    // Buscamos los elementos del formulario por medio de los targets
    this.institutionNameField = container.querySelector(
      "[data-institution-target='institutionNameField']"
    );
    this.institutionDropdown = container.querySelector(
      "[data-institution-target='institutionDropdown']"
    );
    this.institutionIdField = container.querySelector(
      "[data-institution-target='institutionIdField']"
    );

    // Completamos los campos del formulario con los datos de la institución
    this.institutionNameFieldTarget.value = institution.name;
    this.institutionIdFieldTarget.value = institution.id;
  }
}
