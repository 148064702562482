import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
    static targets = [ "days",  "labels", "title", "availability_id", "form", "target"];

    
    initialize() {
        // Create a global array to map numbers to day text
        this.daysMap = [
            "domingo", // 0
            "lunes",   // 1
            "martes",  // 2
            "miercoles", // 3
            "jueves",  // 4
            "viernes", // 5
            "sabado"   // 6
        ];
    }

    day_change({ params: { day } }) {
        this.submitForm()
        const currentDay = this.daysMap[day]

        this.daysTargets.filter(dayTarget => dayTarget.getAttribute("id") == `week_of_day_${day}`).forEach(dayInput => {
            dayInput.checked = true;
            dayInput.disabled = true;
        })
        
        this.daysTargets.filter(dayTarget => dayTarget.getAttribute("id") != `week_of_day_${day}`).forEach(dayInput => {
            dayInput.checked = false;
            dayInput.disabled = false;
        })

        this.labelsTargets.filter(label => label.getAttribute("for") == currentDay).forEach(label => {
            label.classList.add("text-gray-400");
        });

        this.labelsTargets.filter(label => label.getAttribute("for") != currentDay).forEach(label => {
            label.classList.remove("text-gray-400");
        });

        this.titleTargets.forEach(title => {
            title.innerHTML = `${currentDay}`;
        });
        
        this.availability_idTarget.value = day;
    }

    async submitForm() {
    
        const form = this.formTarget;
    
        // Serialize the form data
        const formData = new FormData(form);
    
        // Send the form data via fetch
        fetch(form.action, {
          method: form.method,
          body: formData,
          headers: {
            accept: 'application/json'
          },
        })
      }
}