import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["checkbox", "childCheckbox"]

    connect() {
        this.checkboxTarget.addEventListener("change", this.parentCheckboxChanged.bind(this))
    }

    parentCheckboxChanged(event) {
        if (event.target.checked) {
            this.childCheckboxTarget.classList.remove("hidden")
        } else {
            this.childCheckboxTarget.classList.add("hidden")
        }
    }

}