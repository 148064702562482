import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["fileInput"];

  toggleFileInput(event) {
    const checkbox = event.target;
    const specialtyId = checkbox.value;
    const fileInput = this.fileInputTarget.querySelector(`[data-specialty-id="${specialtyId}"]`);

    if (checkbox.checked) {
      fileInput.style.display = "block";
    } else {
      fileInput.style.display = "none";
    }

    // Obtén el usuario y especialidad relacionados
    const userId = checkbox.getAttribute("data-user-id");
    const userSpecialtyId = checkbox.getAttribute("data-user-specialty-id");

    // Envía un Turbo Stream para actualizar la sección de acreditación
    this.element.dispatchEvent(
      new CustomEvent("turbo:stream", {
        bubbles: true,
        detail: {
          target: `accreditations_${userId}_${userSpecialtyId}`,
          content: `<div id="accreditations_${userId}_${userSpecialtyId}">${fileInput.innerHTML}</div>`
        }
      })
    );
  }
}
