import { Application } from "@hotwired/stimulus"
import FileFieldController from "./file_Field_controller.js";
import PhoneFormatController from "./phone_format_controller.js";

const application = Application.start()


// Configure Stimulus development experience
application.debug    = false
window.Stimulus      = application
application.register("phone-format", PhoneFormatController);
application.register("file_Field", FileFieldController);

export { application }
