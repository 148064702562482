import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "message"];

  connect() {
    this.preselectFile();
  }

  preselectFile() {
    const fileInput = this.inputTarget;
    const attachedDocument = this.data.get("attachedDocument");

    if (fileInput && attachedDocument && fileInput.dataset.preselected === "false") {
      const blob = JSON.parse(attachedDocument);
      const blobData = { filename: blob.filename, byte_size: blob.byte_size, checksum: blob.checksum, content_type: blob.content_type, id: blob.id };
      const newFile = new File([blobData], blob.filename, { type: blob.content_type });

      fileInput.files = new FileList([newFile]);
      fileInput.dataset.preselected = "true";
    }
  }

  removeFile() {
    const fileInput = this.inputTarget;
    const message = this.messageTarget;

    if (fileInput) {
      // Verificar si hay un archivo adjunto
      if (fileInput.files.length > 0) {
        const fileId = fileInput.files[0].id; // Ajusta esto según la estructura real del objeto de archivo

        // Aquí deberías hacer una solicitud a tu servidor para eliminar solo el archivo
        // Puedes usar fetch o alguna librería para hacer la solicitud DELETE
        fetch(`/specialty_users/${fileId}`, {
          method: "DELETE",
        })
          .then((response) => {
            if (response.ok) {
              // Lógica para eliminar solo el archivo seleccionado del cliente
              fileInput.value = "";
              fileInput.dataset.preselected = "false";

              // Puedes realizar acciones adicionales relacionadas con el formulario si es necesario
              // Por ejemplo, desencadenar un evento de cambio en el campo de archivo
              const event = new Event("change", { bubbles: true });
              fileInput.dispatchEvent(event);

              // Ocultar el mensaje si está visible
              if (message) {
                message.style.display = "none";
              }
            } else {
              console.error("Error al intentar eliminar el archivo");
            }
          })
          .catch((error) => console.error("Error en la solicitud DELETE:", error));
      } else {
        // Mostrar mensaje indicando que no hay archivos para borrar
        if (message) {
          message.style.display = "block";
        }
      }
    }
  }
}
