import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["toasts"]

    connect() {
        this.toastsTargets.forEach(alert => {
            // Configura un temporizador para cerrar el toast automáticamente después de 3 segundos
            setTimeout(() => {
                this.close(alert);
            }, 3000);
        });
        // this.closesTargets[index].addEventListener('click', () => {
        //     this.close(success);
        // });
    }

    close(element) {
        element.style.transform = "translateX(150%)"; // Mueve el elemento hacia la derecha
        element.addEventListener('transitionend', () => {
            element.remove(); // Elimina el elemento del DOM después de la transición
        });
    }
}