import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["submitButton"]
  resetForm(event) {
    event.target.reset()
  }

  handleResponse(event) {
    const stepsController = this.application.getControllerForElementAndIdentifier(
      document.querySelector('[data-controller="steps"]'),
      "steps"
    );
    if (event.detail.fetchResponse.response.status === 200) {
      stepsController.next();
    }
  }
}