import { Controller } from "@hotwired/stimulus"
import { createPopper } from "@popperjs/core"

export default class extends Controller {
  static targets = ["button", "content"]

  connect() {
    this.popover = createPopper(this.buttonTarget, this.contentTarget, {
      placement: 'bottom',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 8],
          },
        },
      ],
    })
  }

  toggle() {
    this.contentTarget.classList.toggle('invisible')
    this.contentTarget.classList.toggle('opacity-0')
  }

  show() {
    this.contentTarget.classList.remove('invisible', 'opacity-0')
    this.popover.update()
  }

  hide() {
    this.contentTarget.classList.add('invisible', 'opacity-0')
  }

  disconnect() {
    if (this.popover) {
      this.popover.destroy()
    }
  }
}