import { Controller } from "@hotwired/stimulus"


export default class extends Controller {

    send({ params: {url, method, params = {} } }){

        // Parse the params if it's a string
        const parsedParams = typeof params === 'string' ? JSON.parse(params) : params;

          
        // Realiza la solicitud fetch
        fetch(url, {
            method: method,
            headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector("[name='csrf-token']").content, // necesitas agregar el token CSRF para las solicitudes POST en Rails
            Accept: "text/vnd.turbo-stream.html" // Importante para que Turbo pueda procesar la respuesta
            },
            body: JSON.stringify(parsedParams)
        })
        .then(r => r.text())
        .then(html => Turbo.renderStreamMessage(html));
    }
}
    