import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="faq"
export default class extends Controller {
  static targets = ["content", "icon", "button"]

  toggle(event) {
    const faqId = event.currentTarget.dataset.faqId
    const content = this.contentTargets.find(element => element.dataset.faqId === faqId)
    const icon = this.iconTargets.find(element => element.dataset.faqId === faqId)

    // Toggle visibility of the selected content
    content.classList.toggle("hidden")

    // Toggle icon rotation and styling
    icon.classList.toggle("rotate-45")
    icon.classList.toggle("text-gray-900")
  }
}
