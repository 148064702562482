import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["radio", "childRadio", "childDiv"]

    connect() {
        this.radioTargets.forEach(radio => {
            radio.addEventListener("change", this.parentRadioChanged.bind(this))
        })
    }

    parentRadioChanged(event) {
        const clickedRadio = event.target
        const correspondingChildDiv = this.childDivTargets[this.radioTargets.indexOf(clickedRadio)]

        // Hide all child divs
        this.childDivTargets.forEach(div => div.classList.add("hidden"))

        // Uncheck all child radio buttons
        this.childRadioTargets.forEach(radio => radio.checked = false)

        // Show the child div corresponding to the clicked radio button
        if (clickedRadio.checked) {
            correspondingChildDiv.classList.remove("hidden")
        }
    }
}