import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "results"]

  connect() {
    document.addEventListener('click', this.handleOutsideClick);
  }

  disconnect() {
    document.removeEventListener('click', this.handleOutsideClick);
  }

  handleOutsideClick = (event) => {
    if (!this.element.contains(event.target)) {
      this.resultsTarget.classList.add('hidden');
    }
  }

  search() {
    const searchTerm = this.inputTarget.value.toLowerCase();
    const results = this.resultsTarget.querySelectorAll("[data-item-name]");
    let found = false;
  
    results.forEach(result => {
      const isVisible = result.getAttribute("data-item-name").includes(searchTerm);
      result.classList.toggle('hidden', !isVisible);
      if (isVisible) found = true;
    });
  
    this.resultsTarget.classList.toggle('hidden', !found);
  }

  selectItem(event) {
    const itemName = event.currentTarget.textContent.trim();
    const itemId = event.currentTarget.dataset.itemId;
    
    // Update the visible input field
    this.inputTarget.value = itemName;
  
    // Update the hidden field with the ID
    document.getElementById("specialty_id").value = itemId;
  
    this.resultsTarget.classList.add('hidden');
  }
}