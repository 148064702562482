import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["field", "street", "apartment", "exterior_number", "postal_code", 
                    "city", "state", "lat", "long"]

  connect() {
    if (window.google && window.google.maps && window.google.maps.places) {
      this.initializeAutocomplete();
    } else {
      window.addEventListener('load', () => this.initializeAutocomplete());
    }
  }

  initializeAutocomplete() {
    if (!this.fieldTarget) return;

    this.autocomplete = new google.maps.places.Autocomplete(this.fieldTarget);
    this.autocomplete.setFields(["address_components", "geometry", "icon", "name"]);
    this.autocomplete.addListener("place_changed", this.placeChanged.bind(this));
  }

  placeChanged() {
    let place = this.autocomplete.getPlace()
    if (!place.geometry) {
      window.alert(`No details available for input: ${place.name}`)
      return
    }

    this.latTarget.value = place.geometry.location.lat()
    this.longTarget.value = place.geometry.location.lng() 
    place.address_components.forEach((component) => {
      const type = component.types[0]
      const value = component.long_name
      switch (type) {
        case "route":
          this.streetTarget.value = value
          break
        case "subpremise":
          this.apartmentTarget.value = value
          break
        case "postal_code":
          this.postal_codeTarget.value = value
          break
        case "street_number":
          this.exterior_numberTarget.value = value
          break
        case "locality":
          this.cityTarget.value = value
          break
        case "administrative_area_level_1":
          this.stateTarget.value = value
          break
      }
    })
  }
}