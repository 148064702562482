import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["accountForm", "personalInfoForm"]

  showPersonalInfoForm() {
    this.accountFormTarget.classList.add("hidden")
    this.personalInfoFormTarget.classList.remove("hidden")
  }

  showAccountForm() {
    this.personalInfoFormTarget.classList.add("hidden")
    this.accountFormTarget.classList.remove("hidden")
  }
}