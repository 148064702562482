import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
    static targets = [ "dropdown_container", "dropdown_button", "country_container_button", "dropdown_search_city", "ccInput", "mobile_phone" ];

    connect() {
        this.getCountryCode();
        this.hideCountries();
    }

    hideCountries(){
        this.country_container_buttonTarget.addEventListener('click', ()=>{
            this.dropdown_search_cityTarget.classList.toggle('hidden');
        });
    }

    async getCountryCode(){
        try {
            //const response = await fetch('/api/v1/country_codes');
            //console.log(response);
            //const data = await response.json();
            const data = {
                            "data": [
                                {
                                "id": 1,
                                "name": "MÃ©xico",
                                "lada": "MXN",
                                "flag": "/assets/flag-mexico-0fc2a0e8c81b1bea9203ed0e067a29cc74eb32f1a5b8a3216ba9a103fda9218d.svg"
                                },
                                {
                                "id": 2,
                                "name": "Estados Unidos/Canada",
                                "lada": "USD",
                                "flag": "/assets/flag-usa-4b5f4fa8191b0ed9d2f8bf534ee547cd7763f3bf396ccea8b21706ebab871cb9.svg"
                                }
                            ]
                        };
            data.data.forEach(country => {
                const li = document.createElement("li");
                li.innerHTML = `
                <button type="button" id="country-dropdown" class="inline-flex w-full px-4 py-2 text-sm text-gray-700 dark:text-gray-300 bg-gray-100 dark:bg-[#404040]" role="menuitem" data-currency-target="ticketPatientSearch">
                    <div class="inline-flex items-center">
                        <img src="${country.flag}" alt="${country.name}" class="h-3.5 w-3.5 mr-2">
                        <p class="text-gray-600 dark:text-gray-300">${country.lada}</p>
                    </div>
                </button>`;
                li.addEventListener('click', ()=>{
                    this.dropdown_buttonTarget.innerHTML = `
                        <img src="${country.flag}" alt="${country.name}" class="h-3.5 w-3.5 mr-2">
                        <p class="text-gray-600 dark:text-gray-300">${country.lada}</p>
                    `;
                    this.dropdown_search_cityTarget.classList.toggle('hidden');
                    this.ccInputTarget.value = country.id;
                });
                this.dropdown_containerTarget.appendChild(li);
            });
        }catch(error){
            console.error('Error: ', error);
        }
    }
}