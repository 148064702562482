import { Controller } from "@hotwired/stimulus"

const options = {
  enableHighAccuracy: true,
  maximumAge: 0
};

// Connects to data-controller="geolocation"
export default class extends Controller {
  static targets = ["modal"];
  static values = { url: String }

  connect() {
    // Instead of automatically setting position, we'll wait for user action
    
    this.checkPermissionStatus();
  }

  checkPermissionStatus() {
    
    const city = this.getCookie("city")
    if (navigator.permissions && navigator.permissions.query) {
      navigator.permissions.query({ name: 'geolocation' }).then(result => {
        if (result.state === 'granted' || result.state === 'prompt') {
          this.set_position();
        } else if (!city) {
          // Permission denied
          this.handlePermissionDenied();  
        }
      });
    } 
  }

  handlePermissionDenied() {
    console.log('Geolocation permission denied');
    if (this.hasModalTarget) {
      this.modalTarget.click();
    }
  }

  requestPermission() {
    this.set_position();
  }

  select_position({ params: { latitude, longitude } }) {
    this.sendPositionToServer(latitude, longitude);
  }

  set_position() {
    navigator.geolocation.getCurrentPosition(this.success.bind(this), this.error.bind(this), options);
  }

  success(pos) {
    const crd = pos.coords;
    console.log(`Your current position is: ${crd.latitude} ${crd.longitude}`);
    this.sendPositionToServer(crd.latitude, crd.longitude);
  }

  error(err) {
    if (this.hasModalTarget) {
      this.modalTarget.click();
    }
    console.log(`ERROR(${err.code}): ${err.message}`);
  }

  getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  sendPositionToServer(latitude, longitude) {
    fetch(this.urlValue, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
        Accept: "text/vnd.turbo-stream.html"
      },
      body: JSON.stringify({ latitude, longitude })
    })
    .then(r => r.text())
    .then(html => Turbo.renderStreamMessage(html));
  }
}