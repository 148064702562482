import { Slideover } from "tailwindcss-stimulus-components"

export default class ButtonDropdown extends Slideover {
    static targets = ["slide"]

    connect(){
        super.connect()
    }

    show() {
        this.slideTarget.classList.remove("hidden")
        super.show()
    }

}