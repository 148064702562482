/* eslint no-console:0 */

// Rails functionality
import Rails from "@rails/ujs";
import "@hotwired/turbo-rails";

// Make accessible for Electron and Mobile adapters
window.Rails = Rails;

require("@rails/activestorage").start();
import "@rails/actiontext";

// ActionCable Channels
import "./channels";

// Stimulus controllers
import "./controllers";

import "flowbite";
import "flowbite/dist/flowbite.turbo.js";
import "flowbite-datepicker";
// Jumpstart Pro & other Functionality
import "./src/**/*";
require("local-time").start();

// Start Rails UJS
Rails.start();

// Controlador de Google Maps
window.initMap = () => {
  window.dispatchEvent(new Event("MapLoaded"));
};

// Listener para el evento MapLoaded
window.addEventListener("MapLoaded", () => {
  // console.log('MapLoaded event dispatched');
});
