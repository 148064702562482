import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['content','mother','child','svg'];

  connect() {
    this.hideMenu();
  }

  toggleMenu() {
    this.contentTarget.classList.toggle('hidden');
    this.motherTarget.classList.toggle('border');
    this.motherTarget.classList.toggle('border-gray-200');
    this.motherTarget.classList.toggle('hover:border-gray-300');
    this.motherTarget.classList.toggle('border-2');
    this.motherTarget.classList.toggle('border-indigo-600');
    this.childTarget.classList.toggle('mb-4');
    if (this.contentTarget.classList.contains('hidden')){
      this.svgTarget.innerHTML = `
        <svg class="relative top-1" width="18" height="18" viewbox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.25 6.75L9 12L3.75 6.75" stroke="#18181B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>`;
    } else {
      this.svgTarget.innerHTML = `
      <svg class="relative top-1 text-green-500" width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.16732 12.5L10.0007 6.66667L15.834 12.5" stroke="#4F46E5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
      </svg>`;
    }
  }

  hideMenu() {
    this.contentTarget.classList.add('hidden');
  }
}