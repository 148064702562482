import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab"]

  connect() {
    this.activateTab(this.tabTargets[0])
  }

  select(event) {
    this.activateTab(event.currentTarget)
  }

  activateTab(tab) {
    this.tabTargets.forEach((t) => {
      if (t === tab) {
        t.classList.add("active", "text-gray-900", "bg-gray-100", "dark:bg-gray-700", "dark:text-white")
        t.classList.remove("bg-white", "hover:text-gray-700", "hover:bg-gray-50", "dark:hover:text-white", "dark:bg-gray-800", "dark:hover:bg-gray-700")
      } else {
        t.classList.remove("active", "text-gray-900", "bg-gray-100", "dark:bg-gray-700", "dark:text-white")
        t.classList.add("bg-white", "hover:text-gray-700", "hover:bg-gray-50", "dark:hover:text-white", "dark:bg-gray-800", "dark:hover:bg-gray-700")
      }
    })
  }
}