import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="modals"
export default class extends Controller {
  connect() {
    // Bloquear el scroll del fondo al abrir el modal
    document.body.classList.add("overflow-hidden");
  }

  closeModal() {
    const modal = document.getElementById("modal");
    
    if (modal) {
      // Limpiar contenido del modal
      modal.innerHTML = "";

      // Eliminar atributos innecesarios
      modal.removeAttribute("src");
      modal.removeAttribute("complete");
    }

    // Restaurar el scroll del fondo
    document.body.classList.remove("overflow-hidden");
  }

  closeIfOutside(event) {
    // Verificar si el clic ocurrió fuera del contenido del modal
    if (event.target === this.element) {
      this.closeModal();
    }
  }
}
