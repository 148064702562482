import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["field", "text"];

  connect() {
    const fileInput = this.fieldTarget;
    const fileInputText = this.textTarget;

    fileInput.addEventListener("change", () => {
      const fileName = fileInput.value;

      // Update the text content or do additional processing
      fileInputText.textContent = fileName;
    });
  }
}
