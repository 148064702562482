// app/javascript/controllers/phone_format_controller.js
import { Controller } from "@hotwired/stimulus";
import Inputmask from "inputmask";

export default class extends Controller {
  connect() {
    
    this.initializeInputmask();
  }

  initializeInputmask() {
    // Inicializa Inputmask con el formato deseado
    Inputmask({ mask: '(999) 999-9999' }).mask(this.element);
  }
}
