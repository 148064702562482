import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "dropdown_container",
    "dropdown_button",
    "country_container_button",
    "dropdown_search_city",
    "cc_input",
    "mobile_phone",
  ];

  connect() {
    this.hideCountries();
  }

  hideCountries() {
    this.country_container_buttonTarget.addEventListener("click", () => {
      this.dropdown_search_cityTarget.classList.toggle("hidden");
    });
  }

  toggleCountryList() {
    this.dropdown_search_cityTarget.classList.toggle("hidden");
  }

  selectCountry({ params: { id, lada, flag } }) {
    
    this.dropdown_buttonTarget.innerHTML = `
            <img src="${flag}" alt="Selected country" class="h-3.5 w-3.5 mr-2">
            <p class="text-gray-600 dark:text-gray-300">${lada}</p>
        `;
    this.toggleCountryList();
    this.cc_inputTarget.value = id;
  }
}
