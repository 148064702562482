import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["map"]
  static values = { lat: Number, long: Number, locations: Array }

  connect() {
    if (window.google && window.google.maps && window.google.maps.places) {
      this.initMap();
    } else {
      window.addEventListener('load', () => this.initMap());
    }
  }

  initMap() {
    const mapOptions = {
      center: this.locationsValue.length > 0 
        ? { lat: parseFloat(this.locationsValue[0].latitude), lng: parseFloat(this.locationsValue[0].longitude) }
        : { lat: parseFloat(this.latValue), lng: parseFloat(this.longValue) },
      zoom: this.locationsValue.length > 0 ? 12 : 15
    };

    const map = new google.maps.Map(this.mapTarget, mapOptions);

    if (this.locationsValue.length > 0) {
      console.log(this.locationsValue);
      this.locationsValue.forEach(location => {
        const marker = new google.maps.Marker({
          position: { lat: parseFloat(location.latitude), lng: parseFloat(location.longitude) },
          title: location.name,
          map: map
        });

        const contentString = `
          <div>
            <h5>${location.name}</h5>
            <p>${location.address}</p>
          </div>
        `;

        const infowindow = new google.maps.InfoWindow({
          content: contentString,
          ariaLabel: location.name,
        });

        marker.addListener('click', () => {
          infowindow.open({
            anchor: marker,
            map,
          });
        });
      });
    } else {
      const marker = new google.maps.Marker({
        position: { lat: parseFloat(this.latValue), lng: parseFloat(this.longValue) },
        map: map
      });

      const contentString = `
        <div>
          <h1>Location</h1>
          <p>Latitude: ${this.latValue}</p>
          <p>Longitude: ${this.longValue}</p>
        </div>
      `;

      const infowindow = new google.maps.InfoWindow({
        content: contentString,
        ariaLabel: "Location",
      });

      marker.addListener('click', () => {
        infowindow.open({
          anchor: marker,
          map,
        });
      });
    }
  }
}