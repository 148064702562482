import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["hiddenField"];

  toggleChip(event) {
    event.preventDefault();
    const chip = event.currentTarget;
    const accountType = chip.dataset.accountType;

    if (chip.classList.contains('bg-blue-700')) {
      chip.classList.remove(
        'bg-blue-700', 'text-white', 'hover:bg-blue-800', 'focus:ring-blue-300', 'dark:bg-blue-600', 'dark:hover:bg-blue-700', 'dark:focus:ring-blue-800'
      );
      chip.classList.add(
        'bg-white', 'text-gray-900', 'border-gray-300', 'hover:bg-gray-100', 'focus:ring-gray-100', 'dark:border-gray-600', 'dark:bg-gray-800', 'dark:text-white', 'dark:hover:border-gray-600', 'dark:hover:bg-gray-700', 'dark:focus:ring-gray-700'
      );
      chip.querySelector('svg')?.remove(); // Remove SVG if it exists
    } else {
      chip.classList.remove(
        'bg-white', 'text-gray-900', 'border-gray-300', 'hover:bg-gray-100', 'focus:ring-gray-100', 'dark:border-gray-600', 'dark:bg-gray-800', 'dark:text-white', 'dark:hover:border-gray-600', 'dark:hover:bg-gray-700', 'dark:focus:ring-gray-700'
      );
      chip.classList.add(
        'bg-blue-700', 'text-white', 'hover:bg-blue-800', 'focus:ring-blue-300', 'dark:bg-blue-600', 'dark:hover:bg-blue-700', 'dark:focus:ring-blue-800'
      );
      chip.innerHTML = `${this.createCheckSVG()} ${chip.innerText.trim()}`; // Add SVG
    }

    this.updateHiddenField();
    this.element.closest('form').requestSubmit();
  }

  createCheckSVG() {
    return `
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check mr-2" viewBox="0 0 16 16">
        <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"/>
      </svg>
    `;
  }

  updateHiddenField() {
    const activeChips = Array.from(this.element.querySelectorAll('.bg-blue-700'));
    const activeAccountTypes = activeChips.map(chip => chip.dataset.accountType);
    this.hiddenFieldTarget.value = JSON.stringify(activeAccountTypes);
  }
}