// app/javascript/controllers/ratings_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["star", "rating"]
  static values = { 
    rating: Number,
    max: Number,
    reviewId: String
  }

  connect() {
    this.updateStars()
  }

  rate(event) {
    const starValue = parseFloat(event.currentTarget.dataset.ratingsIndex)
    this.updateStars(starValue)
  }

  reset() {
    this.updateStars()
  }

  setRating(event) {
    const starValue = parseFloat(event.currentTarget.dataset.ratingsIndex)
    this.ratingValue = starValue
    this.updateStars()
    this.ratingTarget.value = starValue

    // Here you might want to send an AJAX request to update the rating in your backend
  }

  updateStars(hoverRating = null) {
    const rating = hoverRating || this.ratingValue
    this.starTargets.forEach((star, index) => {
      const starIndex = index + 1
      const clipPath = star.querySelector(`clipPath[id^="starClip${this.reviewIdValue}"] rect`)
      if (clipPath) {
        if (starIndex <= rating) {
          clipPath.setAttribute('width', '100%')
        } else if (starIndex - 1 < rating && rating < starIndex) {
          const partialFill = (rating - (starIndex - 1)) * 100
          clipPath.setAttribute('width', `${partialFill}%`)
        } else {
          clipPath.setAttribute('width', '0%')
        }
      }
    })
  }
}